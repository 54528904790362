<template>
    <div class="box">
        <div class="bg1"></div>
        <div class="bg2"></div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.box{
    position: relative;
    .bg1{
    width: 414px;
    height: 318px;
    position: absolute;
    left: -120px;
    bottom: 0;
    background: url("./redBg1.png") no-repeat ;
    background-size: cover;
    opacity: 0.05;
  }
  .bg2{
    width: 223px;
    height: 316px;
    position: absolute;
    right: -30px;
    bottom: -20px;
    background: url("./redBg1.png") no-repeat;
    background-size: cover;
    opacity: 0.05;
  }
}
</style>