<template>
  <bg-page>
    <div class="z-project-detail" style="margin-top: 10px">
      <div class="header">
        <RedBgBox>
          <div class="limitBox content">
            <div class="imgBox">
              <p class="null" v-if="!state.projectInfo.img">
                暂无<br />
                项目图
              </p>
              <el-image
                v-else
                :src="state.projectInfo.img"
                fit="cover"
                style="width: 680px; height: 360px"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="main">
              <p class="title">项目基本信息</p>
              <p class="details">
                <span>实施所在地：</span>
                <span v-if="state.projectInfo.city">{{
                  state.projectInfo.city.name
                }}</span>
              </p>
              <p class="details">
                <span>行业类别：</span>
                <span v-if="state.projectInfo.cate">{{
                  state.projectInfo.cate.name
                }}</span>
              </p>
              <p class="details">
                <span>投资规模：</span>
                <span>{{ state.projectInfo.scale }}万元</span>
              </p>
              <p class="details">
                <span>已完成投资：</span>
                <span>{{ state.projectInfo.invested }}万元</span>
              </p>
              <p class="details">
                <span>类别：</span><span>{{ state.projectInfo.type == 1 ? '在建' : '备键' }}</span>
              </p>
              <p class="details">
                <span>进展状态：</span>
                <span>{{ state.projectInfo.state == 1 ? '异常' : '正常' }}</span>
              </p>
            </div>
          </div>
        </RedBgBox>
      </div>
      <div class="mainBox BackgroundBox">
        <div class="limitBox content" ref="jumpBox">
          <div class="left">
            <div class="synopsis">
              <p class="hTitle">项目介绍</p>
              <div class="intro">
                <el-tabs tab-position="left" style="height: 200px">
                  <el-tab-pane label="项目内容">
                    <p class="stepTitle">项目内容</p>
                    <p
                      class="stepValue"
                      v-html="state.projectInfo.html.html"
                    ></p>
                  </el-tab-pane>
                  <el-tab-pane label="可行性论证">
                    <p class="stepTitle">可行性论证</p>
                    <p
                      class="stepValue"
                      v-html="state.projectInfo.html.feasibility"
                    ></p>
                  </el-tab-pane>
                  <el-tab-pane label="资金来源">
                    <p class="stepTitle">资金来源</p>
                    <p
                      class="stepValue"
                      v-html="state.projectInfo.html.fund"
                    ></p>
                  </el-tab-pane>
                  <el-tab-pane label="效益分析">
                    <p class="stepTitle">效益分析</p>
                    <p
                      class="stepValue"
                      v-html="state.projectInfo.html.benefit"
                    ></p>
                  </el-tab-pane>
                  <el-tab-pane label="手续办理">
                    <p class="stepTitle">手续办理</p>
                    <p
                      class="stepValue"
                      v-html="state.projectInfo.html.procedures"
                    ></p>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <div class="schedule">
              <p class="hTitle">项目进度</p>
              <div class="progress">
                <div class="leftBox">
                  <p>
                    <span>项目开始时间：</span
                    ><span>{{ state.projectInfo.start }}</span>
                  </p>
                  <p>
                    <span>项目完成时间：</span
                    ><span>{{ state.projectInfo.end }}</span>
                  </p>
                </div>
                <div class="rightBox">
                  <p>完成度:</p>
                  <p v-if="state.projectInfo.processList.length">
                    {{
                      state.projectInfo.processList[
                        state.projectInfo.processList.length - 1
                      ].percent
                    }}%
                  </p>
                </div>
              </div>
              <div>
                <el-timeline>
                  <el-timeline-item
                    v-for="(item, index) in state.projectInfo.processList"
                    :key="index"
                    :timestamp="item.date"
                    color="#7F0404"
                    size="normal"
                    placement="top"
                  >
                    <p>{{ item.percent }}%</p>
                    <p>{{ item.info }}</p>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
          </div>
          <div class="right">
            <div>
              <p class="hTitle">项目单位情况</p>
              <p class="name">单位名称</p>
              <p class="value">{{ state.projectInfo.enterpriseInfo.title }}</p>
              <p class="name">单位性质</p>
              <p class="value" v-if="state.projectInfo.enterpriseInfo.nature">{{ state.natureOptions[state.projectInfo.enterpriseInfo.nature].label }}</p>
              <p class="name">注册资金</p>
              <p class="value">
                {{ state.projectInfo.enterpriseInfo.capital }} 万元
              </p>
            </div>

            <el-divider class="divider" />

            <div>
              <p class="hTitle">联系方式</p>
              <p class="name">联系人</p>
              <p class="value">
                {{ state.projectInfo.enterpriseInfo.html.linkman }}
              </p>
              <p class="name">业务电话</p>
              <p class="value">
                {{ state.projectInfo.enterpriseInfo.html.tell }}
              </p>
              <p class="name">电子邮箱</p>
              <p class="value">
                {{ state.projectInfo.enterpriseInfo.html.email }}
              </p>
              <p class="name">邮编</p>
              <p class="value">
                {{ state.projectInfo.enterpriseInfo.html.postal }}
              </p>
              <p class="name">地址</p>
              <p class="value">
                {{ state.projectInfo.enterpriseInfo.html.address }}
              </p>
            </div>

            <div
              class="map"
              v-if="state.projectInfo.enterpriseInfo.html.location"
            >
              <Map
                :longitude="
                  parseFloat(
                    state.projectInfo.enterpriseInfo.html.location.split(',')[0]
                  )
                "
                :latitude="
                  parseFloat(
                    state.projectInfo.enterpriseInfo.html.location.split(',')[1]
                  )
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import { useRoute } from "vue-router";
import Map from "../../components/Map/Map";
import RedBgBox from "../../components/RedBgBox/RedBgBox.vue";
import api from "@/comment/request/api";
export default {
  name: "ProjectDetails",
  components: { Map, RedBgBox },
  setup() {
    const route = useRoute();
    const state = Vue.reactive({
      projectInfo: { html: {}, enterpriseInfo: { html: {} }, processList: [] },
      natureOptions: [
        {
          value: 0,
          label: "其他",
        },
        {
          value: 1,
          label: "国有(控股)企业",
        },
        {
          value: 2,
          label: "民营(控股)企业",
        },
        {
          value: 3,
          label: "集体企业",
        },
        {
          value: 4,
          label: "外资企业",
        },
        {
          value: 5,
          label: "事业单位",
        },
      ],
    });
    api.GetProjectDetails(route.query.id).success((res) => {
      state.projectInfo = res.data.data;
    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.Box {
  padding-bottom: 0;
}
.z-project-detail {
  .header {
    background: #fff;
    overflow: hidden;
    .content {
      display: flex;
      .imgBox {
        width: 680px;
        height: 360px;
        margin-right: 40px;
        background: #d8d8d8;
        position: relative;
        .null {
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
      }
      .main {
        flex: 1;
        .title {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #7f0404;
          line-height: 36px;
          margin: 0 0 98px;
        }
        .details {
          margin: 0 0 20px;
          span {
            display: inline-block;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            &:nth-child(1) {
              width: 96px;
            }
            &:nth-child(2) {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .mainBox {
    .content {
      display: flex;
      padding: 40px 0 0 0;
      .left {
        width: 1040px;
        margin-right: 40px;
        padding-bottom: 40px;
        .synopsis,
        .schedule {
          background: #fff;
          padding: 30px;
          box-sizing: border-box;
          z-index: 222;
          .hTitle {
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #7f0404;
            line-height: 36px;
            border-left: 5px solid #7f0404;
            padding-left: 25px;
            margin-left: -30px;
          }
        }
        .synopsis {
          height: 600px;
          margin-bottom: 40px;
          .intro {
            height: 474px;
            margin-top: 30px;
            .el-tabs--left {
              height: 100% !important;
            }
            /deep/ .el-tabs__item {
              font-size: 24px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              line-height: 24px;
              margin: 40px 0;
              &:hover {
                color: #7f0404;
              }
            }
            /deep/ .is-active {
              color: #7f0404;
            }
            /deep/ .el-tabs__active-bar {
              background: #7f0404;
              width: 4px;
            }
            /deep/ .el-tabs__content {
              height: 100%;
              overflow-y: auto;
              padding: 0 20px;
              &::-webkit-scrollbar {
                width: 10px;
                height: 100%;
                background-color: #d8d8d8;
              }
              &::-webkit-scrollbar-thumb {
                width: 6px;
                height: 50px;
                background-color: #fff;
              }
            }
            .stepTitle,
            .stepValue {
              font-size: 24px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 24px;
            }
            .stepValue {
              font-size: 16px;
              font-weight: 400;
              margin: 15px 0 30px;
            }
          }
        }
        .schedule {
          .progress {
            display: flex;
            height: 94px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            margin: 30px 0 40px;
            .leftBox {
              width: 660px;
              background: #f4f4f4;
              border-right: 1px solid rgba(0, 0, 0, 0.15);
              padding: 20px;
              > p {
                &:nth-child(1) {
                  margin-bottom: 20px;
                }
                span {
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 16px;
                  &:nth-child(2) {
                    font-weight: 600;
                  }
                }
              }
            }
            .rightBox {
              flex: 1;
              padding: 20px;
              > p {
                &:nth-child(1) {
                  font-size: 16px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #333333;
                  line-height: 16px;
                }
                &:nth-child(2) {
                  font-size: 36px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #7f0404;
                  line-height: 36px;
                  text-align: right;
                }
              }
            }
          }
          .el-timeline {
            margin-left: 220px;
          }
          /deep/.el-timeline-item__timestamp.is-top {
            padding: 0;
          }
          /deep/.el-timeline-item__timestamp {
            margin-left: -200px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #7f0404;
            line-height: 24px;
          }
          /deep/.el-timeline-item__content {
            margin-top: -32px;
            p {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
              margin-bottom: 30px;
              &:nth-child(1) {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
      .right {
        flex: 1;
        .hTitle {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #7f0404;
          line-height: 36px;
          margin-bottom: 20px;
          margin-top: 30px;
        }
        .name {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
          margin-bottom: 10px;
        }
        .value {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 16px;
          margin-bottom: 20px;
        }
        .divider {
          margin: 40px 0;
        }
        .map {
          width: 320px;
          height: 200px;
        }
      }
    }
  }
}
</style>